import * as React from "react"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

type Props = {
  description?: string,
  lang?: string,
  meta?: any[],
  keywords?: string[],
  title: string,
  siteUrl?: string,
  OGImagePath?: string
}



const SEO = ({ description, lang = 'en', meta = [], keywords = [], title, siteUrl, OGImagePath }: Props) => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        const url = siteUrl || data.site.siteMetadata.siteUrl
        const imagePath = OGImagePath || data.site.siteMetadata.OGImagePath
        const ogImage = `${url}/${imagePath}`
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              // {
              //   name: `twitter:creator`,
              //   content: data.site.siteMetadata.author,
              // },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `twitter:card`,
                content: `summary_large_image`
              },
              {
                name: `og:image`,
                content: ogImage
              },
              {
                name: `og:image:height`,
                content: 1200
              },
              {
                name: `og:image:width`,
                content: 1200
              },
              {
                name: `og:url`,
                content: siteUrl
              }
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        siteUrl
        OGImagePath
      }
    }
  }
`
