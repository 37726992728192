import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { navDetails } from "../constants/navDetails";
import { FaHammer, FaHome, FaPhone } from "react-icons/fa";
import { instagramUrl } from "../constants/navDetails";
import { BBBButton } from "./BBB";
import instagram from "../images/instagram.png";

const FlexItem = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  @media screen and (min-width: 768px) {
    &:nth-child(even) {
      border-right: 1px solid ${(props) => props.theme.bgGreyLight};
      border-left: 1px solid ${(props) => props.theme.bgGreyLight};
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    &:after {
      position: absolute;
      content: "";
      border-bottom: 1px solid ${(props) => props.theme.bgGreyLight};
      width: 80%;
      margin-top: 1rem;
      transform: translateX(-50%);
    }
  }
`;

const Company = styled(FlexItem)`
  text-align: center;
`;

const Contact = styled(FlexItem)`
  text-align: center;
`;

const Address = styled(FlexItem)`
  text-align: center;
`;

const BBB = styled.div`
display: flex;
justify-content: center;
  width: 100%;
  text-align: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
  background-color: ${(props) => props.theme.bgGrey};
  color: ${(props) => props.theme.textWhite};
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding-bottom: 1rem;
  padding-top: 1rem;
  color: ${(props) => props.theme.textWhite};
  background-color: ${(props) => props.theme.bgGrey};

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Copyright = styled.div`
  width: 100%;
  text-align: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
  background-color: ${(props) => props.theme.bgGrey};
  color: ${(props) => props.theme.textWhite};
`;

const Title = styled.div`
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const NavLink = styled(Link)`
  padding: 0.25rem 1rem 0.25rem 1rem;
  color: ${(props) => props.theme.textWhite};
  text-decoration: none;
  @media (hover: hover) {
    &:hover {
      color: ${(props) => props.theme.textGold};
    }
  }
`;

const Icon = styled.span`
  background-color: ${(props) => props.theme.textWhite};
  border-radius: 100%;
  color: ${(props) => props.theme.textGrey};
  display: inline-block;
  height: 2.2rem;
  line-height: 2.4rem;
  text-align: center;
  width: 2.2rem;
  margin-bottom: 0.5rem;
`;

const ProtocolLink = styled.a`
  color: ${(props) => props.theme.textWhite};
  text-decoration: none;
  @media (hover: hover) {
    &:hover {
      color: ${(props) => props.theme.textGold};
    }
  }
`;

const SocialIcon = styled.img`
  width: 50px;
  height: 50px;
`;

const SocialContainer = styled.div`
  padding-top: 0.75rem;
`;

const Footer = () => (
  <footer>
    <Container>
      <Company>
        <Icon>
          <FaHammer />
        </Icon>
        <Title>COMPANY</Title>
        <FlexColumn>
          {navDetails.map((item, i) => {
            const hasSubItems = item.subItems?.length > 0;
            if (hasSubItems) {
              return (
                <React.Fragment key={item.text}>
                {item.subItems?.map((subItem) => (
                  <NavLink key={subItem.text} to={subItem.to}>{subItem.text}</NavLink>
                ))}
                </React.Fragment>)
            }

            return (
            <NavLink key={item.text} to={item.to}>
              {item.text}
            </NavLink>

            )
          })}
        </FlexColumn>
      </Company>
      <Contact>
        <Icon>
          <FaPhone />
        </Icon>
        <Title>CONTACT</Title>
        <FlexColumn>
          <div>
            <ProtocolLink href="mailto:hconcoinc@gmail.com">
              hconcoinc@gmail.com
            </ProtocolLink>
          </div>
          <div>
            <ProtocolLink href="tel:615-440-0419">615-440-0419</ProtocolLink>
          </div>
          <SocialContainer>
            <a href={instagramUrl} target="_blank">
              <SocialIcon src={instagram} alt={"Instagram icon"} />
            </a>
          </SocialContainer>
        </FlexColumn>
      </Contact>
      <Address>
        <Icon>
          <FaHome />
        </Icon>
        <Title>ADDRESS</Title>
        <FlexColumn>
          <address>
            <div>P.O. Box 627</div>
            <div>Ashland City, TN</div>
            <div>37015</div>
          </address>
        </FlexColumn>
      </Address>
    </Container>
    <BBB>
        <BBBButton />
      </BBB>
    <Copyright>© {new Date().getFullYear()}, H-Con-Co Inc.</Copyright>
  </footer>
);

export default Footer;
