import * as React from "react";
import styled from "styled-components";

const BBBLink = styled.a`
display: block;
position: relative;
overflow: hidden; 
width: 95px; 
height: 35px; 
margin: 0px; 
padding: 0px;

@media screen and (max-width: 413px) {

  width: 75px; 
  height: 25px; 
}
`;

const BBBImg = styled.img`
padding: 0px; 
border: none;

width: 190px;
height: 35px;

@media screen and (max-width: 413px) {
  width: 150px; 
  height: 25px; 
}
`;

export const BBBButton = () => (
  <BBBLink
    id="bbblink"
    href="https://www.bbb.org/us/tn/ashland-city/profile/concrete/h-con-0573-37265222#bbbseal"
    title="HConCo Inc, Concrete, Ashland City, TN"
    target="_blank"
  >
    <BBBImg
      id="bbblinkimg"
      src="https://seal-nashville.bbb.org/logo/sehzbum/h-con-37265222.png"
      width="190"
      height="35"
      alt="HConCo Inc, Concrete, Ashland City, TN"
    />
  </BBBLink>
);
