import * as React from "react";
import { Link } from "gatsby";
import styled, { useTheme } from "styled-components";
import { navDetails, NavItem, NavLinkType } from "../constants/navDetails";
import { breakpoints } from "../constants";

const NavLinkContainer: any = styled.nav`
  display: flex;
  flex-direction: ${(props: any) => props.direction};
  flex-wrap: nowrap;
`;

const NavLink: any = styled(Link)`
  padding: 0 1rem 0 1rem;
  color: ${(props: any) =>
    props.active ? props.theme.textGold : props.theme.textWhite};
  text-decoration: none;
  margin-bottom: 0;
  font-size: 1rem;
  
  @media (hover: hover) {
    &:hover {
      color: ${(props) => props.theme.textGold};
    }
  }

  @media (max-width: ${breakpoints.tablet}px) {
    font-size: 0.9rem;
  }
`;

const DropDown = styled.div`
  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown:hover .dropdown-content {
    display: flex;
    opacity: 1;
    visibility: visible;

  }
`;

const DropdownContent = styled.div`
  display: flex;
  position: absolute;
  background-color: ${(props) => props.theme.bgGrey};
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  flex-direction: column;
  left: -0.8rem;
  padding-top: 1.25rem;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  visibility: hidden;

`;

const DropdownNavItem = styled(NavLink)`
  margin: 0 0.5rem 0.5rem 0.5rem;
  text-align: center;

  border-top: 1px solid ${(props) => props.theme.greyBorder};
  padding-top: 0.75rem;
  padding-bottom: 0.25rem;
`;
interface DropdownMenuProps {
  item: NavItem;
}

const DropdownMenu = ({ item }: DropdownMenuProps) => {
  const theme = useTheme();
  return (
    <DropDown>
      <div className="dropdown">
        <NavLink>{item.text}</NavLink>
        <DropdownContent className="dropdown-content">
          {item.subItems?.map((details, i) => (
            <DropdownNavItem
              key={i}
              to={details.to}
              activeStyle={{ color: theme.textGold }}
            >
              {details.text}
            </DropdownNavItem>
          ))}
        </DropdownContent>
      </div>
    </DropDown>
  );
};



const Navigation = ({
  direction = "row",
}: any) => {
  const theme = useTheme();
  return (
    <NavLinkContainer direction={direction}>
      {navDetails.map((details, i) => {
            if (details.type === NavLinkType.DROPDOWN) {
              return <DropdownMenu item={details} />;
            }
            return (
              <NavLink
                key={i}
                to={details.to}
                activeStyle={{ color: theme.textGold }}
              >
                {details.text}
              </NavLink>
            );
          })}
    </NavLinkContainer>
  );
};

export default Navigation;
