export enum NavLinkType {
  DROPDOWN = "DROPDOWN",
}

export interface NavItem {
  to: string;
  text: string;
  type?: string;
  subItems?: NavItem[];
}

export const navDetails: NavItem[] = [
  { to: "/", text: "HOME" },
  { to: "/about", text: "ABOUT US" },
  {
    to: "",
    text: "SERVICES",
    type: NavLinkType.DROPDOWN,
    subItems: [
      { to: "/services/concrete", text: "CONCRETE" },
      { to: "/services/framing", text: "FRAMING" },
      { to: "/services/masonry", text: "MASONRY" },
      { to: "/services/roofing", text: "ROOFING" },
      { to: "/services/handyman-services", text: "HANDYMAN SERVICES" },

    ],
  },
  { to: "/estimate", text: "ESTIMATE" },
];

export const instagramUrl = "https://www.instagram.com/hconcoinc/";
