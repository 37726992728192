import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { withBreakpoints } from "react-breakpoints";
import Navigation from "./navigation";
import { BBBButton } from "./BBB";
import logo from "../images/logo.png";

const StyledHeader = styled.header`
  background-color: ${(props) => props.theme.bgGrey};
  display: flex;
  flex-direction: row;
  height: ${(props) => props.theme.headerHeight};
  align-items: center;
  padding: 0 1rem 0 1rem;
`;

const NavContainer = styled.div`
  margin-left: auto;
`;

const CompanyName = styled.div`
  color: ${(props) => props.theme.textWhite};

  @media screen and (max-width: 365px) {
    display: none;
  }
`;

const Logo = styled.img`
  height: 3rem;
  padding-right: 1rem;
`;

const LinkNoUnderline = styled(Link)`
  text-decoration: none;
`;

const companyName = "H-CON-CO INC.";

const BBBButtonContainer = styled.div`
  margin-left: 20px;

  @media screen and (max-width: 413px) {
    margin-left: 15px;
  }
`;

const DesktopHeader = () => (
  <>
    <LinkNoUnderline to={"/"}>
      <Logo src={logo} alt={"Logo"}></Logo>
    </LinkNoUnderline>
    <LinkNoUnderline to={"/"}>
      <CompanyName>{companyName}</CompanyName>
    </LinkNoUnderline>
    <BBBButtonContainer>
      <BBBButton />
    </BBBButtonContainer>
    <NavContainer>
      <Navigation />
    </NavContainer>
  </>
);

const MobileHeader: any = () => (
  <>
    <LinkNoUnderline to={"/"}>
      <Logo src={logo} alt={"Logo"}></Logo>
    </LinkNoUnderline>
    <LinkNoUnderline to={"/"}>
      <CompanyName>{companyName}</CompanyName>
    </LinkNoUnderline>
    <BBBButtonContainer>
      <BBBButton />
    </BBBButtonContainer>
  </>
);

type HeaderProps = {
  breakpoints: any;
  currentBreakpoint: any;
};

const Header = ({
  breakpoints,
  currentBreakpoint,
}: HeaderProps) => {
  return (
    <StyledHeader>
      {breakpoints && breakpoints[currentBreakpoint] >= breakpoints.tablet ? (
        <DesktopHeader />
      ) : (
        <MobileHeader />
      )}
    </StyledHeader>
  );
};

export default withBreakpoints(Header);
