import * as React from "react";
import styled from 'styled-components';
import { certificationBannerText } from '../constants/textConstants';

const Banner = styled.div`
    width: 100%;
    background-color: ${props => props.theme.bgGreyWhite};
    min-height: 2.5rem;
    align-items: center;
    justify-content: center;
    display: flex;
`;

const Text = styled.span`
    font-style: italic;
    line-height: 2rem;
    color: ${props => props.theme.textLightGrey};

    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;

    @media screen and (max-width: 768px) {
        font-size: 0.85rem;
    }
`;
const CertificationBanner = () => {
    return (
        <Banner>
            <Text>
                {certificationBannerText}
            </Text>
        </Banner>
    )
}

export default CertificationBanner;
