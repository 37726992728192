import * as React from "react";
import { StickyContainer, Sticky } from "react-sticky";
import ReactBreakpoints from "react-breakpoints";
import Header from "./header";
import {
  compose,
  withState,
  withHandlers,
  withPropsOnChange,
  setDisplayName,
} from "recompose";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "../theme";
import Footer from "./footer";
import { breakpoints } from "../constants";
import { Bars } from "./Bars";
import "./layout.css";
import Menu from "react-burger-menu/lib/menus/slide";
import { Link } from "gatsby";
import { withBreakpoints } from "react-breakpoints";
import { navDetails } from "../constants/navDetails";
import CertificationBanner from "./CertificationBanner";

const Content: any = styled(StickyContainer)`
  display: flex;
  flex-direction: column;
  overflow: ${(props: any) => (props.sidebarOpen ? "none" : "auto")};
`;

const Main = styled.main`
  flex-grow: 1;
  min-height: 100vh;
`;

const MobileMenu = styled(Menu)`
  @media screen and (min-width: 768px) {
    display: none;
  }

  width: 160px;
`;


const StyledNonLinkText = styled.div`
  text-decoration: none;
  margin-bottom: 10px;
  color: ${(props) => props.theme.textWhite};
  transition: color 0.2s;
  @media (hover: hover) {
    &:hover {
      color: ${(props) => props.theme.textGold};
    }
  }
  border-bottom: 1px solid ${(props) => props.theme.greyBorder};
  padding-bottom: 0.75rem;
  padding-top: 0.25rem;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  margin-bottom: 10px;
  color: ${(props) => props.theme.textWhite};
  transition: color 0.2s;
  @media (hover: hover) {
    &:hover {
      color: ${(props) => props.theme.textGold};
    }
  }
  border-bottom: 1px solid ${(props) => props.theme.greyBorder};
  padding-bottom: 0.75rem;
  padding-top: 0.25rem;
`;

const MobileSubItem = styled(StyledLink)`
  margin-left: 1rem;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SB = ({ ...props }) => {
  return (
    <MobileMenu {...props}>
      <div>
        {navDetails.map((item, i) => {
          const hasSubItems = item.subItems?.length > 0;
          return (
            <ItemContainer key={`${item.text}_${i}`}>
              {hasSubItems && <StyledNonLinkText>{item.text}</StyledNonLinkText>}
              {!hasSubItems && (
                <StyledLink to={item.to}>{item.text}</StyledLink>
              )}
              {hasSubItems &&
                item.subItems?.map((subItem) => (
                  <MobileSubItem key={subItem.text} to={subItem.to}>{subItem.text}</MobileSubItem>
                ))}
            </ItemContainer>
          );
        })}
      </div>
    </MobileMenu>
  );
};

const Sidebar = styled(SB)`
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const LayoutWithHeader = ({
  sidebarOpen,
  setSidebarOpen,
  sidebarClicked,
  children,
}: any) => (
  <>
    <Sidebar
      pageWrapId={"page-wrap"}
      right={true}
      isOpen={sidebarOpen}
      onStateChange={(s) => setSidebarOpen(s.isOpen)}
      customBurgerIcon={<Bars />}
      width={"165px"}
    />
    <StickyContainer id="page-wrap">
      <Sticky style={{ width: "100%", "font-family": `'Karla', 'sans-serif'` }}>
        {({ style }: any) => (
          <div style={{ ...style, zIndex: 99 }}>
            <Header
              sidebarClicked={sidebarClicked}
              siteTitle={"H-Con-Co Inc."}
            />
          </div>
        )}
      </Sticky>
      <Content>
        <Main>{children}</Main>
        <CertificationBanner />
        <Footer />
      </Content>
    </StickyContainer>
  </>
);

type LayoutProps = {
  children?: any;
  sidebarClicked(): void;
  sidebarOpen: boolean;
  setSidebarOpen(state: boolean);
};

const EnhancedLayout: any = compose(
  withBreakpoints,
  withPropsOnChange(
    ["currentBreakpoint"],
    ({ currentBreakpoint, setSidebarOpen }: any) => {
      breakpoints[currentBreakpoint] >= breakpoints.tablet &&
        setSidebarOpen(false);
    }
  )
)(LayoutWithHeader);

const Layout = ({
  sidebarOpen,
  sidebarClicked,
  setSidebarOpen,
  children,
}: LayoutProps) => (
  <ThemeProvider theme={theme}>
    <ReactBreakpoints breakpoints={breakpoints}>
      <EnhancedLayout
        sidebarClicked={sidebarClicked}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      >
        {children}
      </EnhancedLayout>
    </ReactBreakpoints>
  </ThemeProvider>
);

const withLayoutBehaviors = compose<LayoutProps, {}>(
  withState("sidebarOpen", "setSidebarOpen", false),
  withHandlers({
    sidebarClicked: ({ sidebarOpen, setSidebarOpen }: LayoutProps) => () => {
      setSidebarOpen(!sidebarOpen);
    },
  })
);

export default withLayoutBehaviors(Layout);
