export const theme = {
    bgGrey: "#1e1e1e",
    bgGreyLight: "#353535",
    bgGreyWhite: "#d9d9d9",
    bgWhite: "#fff",
    bgGold: "#a4832a",
    textWhite: '#fff',
    textGrey: "#1e1e1e",
    textGold: "#a4832a",
    headerHeight: '4rem',
    textLightGrey: "#424242",
    disclaimerGrey: "#a2a2a2",
    greyBorder: "#2c2c2c",
  };